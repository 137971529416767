<template>
  <scrollable-container>
    <placeholder
      v-show="state === reportEnum.requestAwaiting"
      :text="text"
      @buttonClick="buildReport"
    />
    <loader v-show="state === reportEnum.tableLoading" />

    <container
      v-show="state === reportEnum.tableReady"
      id="printable"
      :class="[
        { [$style.message]: state === reportEnum.requestAwaiting },
        $style.scrollableContent,
      ]"
    >
      <table :class="$style.table">
        <thead :class="$style.header">
          <th
            v-for="column in columns"
            :key="column.name"
            :style="{ width: column.width }"
          >
            {{ column.name }}
          </th>
        </thead>

        <tbody>
          <tr
            v-for="user in tableData"
            :key="user.name"
            :class="$style.item"
          >
            <td
              v-for="column in columns"
              :key="column.name + user.name"
              :style="{ width: column.width, backgroundColor: getColor(user[column.field], column.field) }"
            >
              {{ getField(user[column.field], column.field) }}
            </td>
          </tr>
        </tbody>
      </table>
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import Loader from '@/components/common/blocks/loader.vue';
import { getViolations } from '@/api/methods/reports/users/index';
import { convertSecondsToDays } from '@/helpers/format-date';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    Loader,
  },
  data: () => ({
    name: 'Нарушения трудовой дисциплины',
    tableData: {},
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    columns: [
      { name: 'Сотрудник', field: 'user' },
      { name: 'Опоздания', field: 'late' },
      { name: 'Уходы ранее', field: 'early' },
      { name: 'Прогулы', field: 'out' },
      { name: 'Недоработка', field: 'underwork' }],
  }),
  computed: {
    dateOption() {
      return this.$store.getters['filters/dateSelect'];
    },
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];

      return tree;
    },
    isWorktimeOnly() {
      return this.$store.getters['filters/isWorktimeOnly'];
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    isWorktimeOnly(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    dateOption(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    getColor(value, field) {
      if (field === 'underwork') {
        if (value > 0) return '#7cd890';
        return '#ffb0b0';
      }
      return '#FFFFFF';
    },
    getField(value, field) {
      if (field === 'underwork') return convertSecondsToDays(value);
      return value;
    },
    getCheckedComputersFromTree(node) {
      if (node.type === 'computer' && node.checked) this.computersArray.push(node);
      if (node.type === 'folder') {
        node.children.forEach((child) => this.getCheckedComputersFromTree(child));
      }
    },
    buildReport() {
      this.state = this.reportEnum.tableLoading;
      this.tableData = {};
      let workTimeOnly = 0;
      this.computersArray = [];

      if (this.computersTree) {
        this.computersTree.forEach((node) => this.getCheckedComputersFromTree(node));
      }

      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersArray
      ) {
        if (this.isWorktimeOnly) workTimeOnly = 1;

        this.promise = getViolations(
          this.computersArray,
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
          workTimeOnly,
        ).then(({ data }) => {
          if (data && data.result) {
            this.tableData = data.result;
            this.state = this.reportEnum.tableReady;

            const exportArray = [];
            this.$store.commit('exportData/setDisciplineViolations', { name: this.name, data: exportArray });
          } else this.state = this.reportEnum.requestAwaiting;

          return data;
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #F5F5F5;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;

  th {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 7px 4px 4px 10px;
    cursor: pointer;
  }
}

.item {
  height: 29px;
  cursor: pointer;
  border-bottom: 0.2px solid #eee;

  &:hover {
    background: $light-gray;
  }

  td {
    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    font-size: 14px;
    letter-spacing: 0px;
    text-align: left;
    padding: 7px 4px 7px 10px;
  }
}
</style>
